import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`  
  * {
    box-sizing: border-box;
    word-wrap: break-word;
  }

  html {
    min-height: 100%;
  }

  html,
  body {
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Inter', sans-serif;
    background-color: #e3f2f2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
  }

  ul,
  menu,
  dir {
    padding-left: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  *:focus,
  *:active {
    outline: 0 !important;
    box-shadow: none !important;
  }

  a,
  button {
    transition: opacity .3s;
    outline: none !important;
    box-shadow: none !important;
  }

  .no-scroll {
    overflow: hidden !important;
  }

  ::-moz-selection { 
    color: #fff;
    background: #008996 !important;
    -webkit-background-clip: none !important;
    -webkit-text-fill-color: #fff !important;
    -webkit-box-decoration-break: none !important;
  }

  ::selection {
    color: #fff;
    background: #008996 !important;
    -webkit-background-clip: none !important;
    -webkit-text-fill-color: #fff !important;
    -webkit-box-decoration-break: none !important;
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
  }
`

export default GlobalStyles
